import { pluck } from "rxjs/operators";
import { IAsset, IProject, IUser } from "models/interfaces";
import { autoinject, observable } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import { connectTo } from "aurelia-store";
import { StateManager } from "services/state-manager";

@autoinject()
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class NewProjectDialog {
    controller: DialogController;
    _authenticationService: AuthenticationService;
    _stateManager: StateManager;

    newProject: IProject = {
        organizationId: "",
        name: "",
        xLightingCoordinate: 0,
        yLightingCoordinate: 0,
        zLightingCoordinate: 0,
        lightingIntensity: 0,
        xOriginCoordinate: 0,
        yOriginCoordinate: 0,
        defaultZoomLevel: 0,
        initialProjectionEPSGCode: "",
    };
    organizationId: string;
    darkUi: boolean;

    @observable projectName: string = "";

    showingErrors: boolean = false;
    projectNameError: string = null;

    constructor(
        controller: DialogController,
        authenticationService: AuthenticationService,
        stateManager: StateManager
    ) {
        this.controller = controller;
        this._authenticationService = authenticationService;
        this._stateManager = stateManager;
    }

    activate(organizationId) {
        this.organizationId = organizationId;
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    projectNameChanged(newValue, oldValue) {
        if (newValue === "" || newValue === null) {
            this.projectNameError = "A project name is required";
            return;
        }
        this.projectNameError = null;
    }

    async save() {
        if (this.projectName == null || this.projectName == "") {
            this.showingErrors = true;
            this.projectNameError = "A project name is required";
        }

        this.newProject.organizationId = this.organizationId;
        this.newProject.name = this.projectName;

        this._stateManager.postProjectAsync(this.newProject, {
            organizationId: this.organizationId,
        });
        this.controller.ok();
    }

    cancel() {
        this.controller.cancel();
    }
}
