import { pluck } from "rxjs/operators";
import { connectTo } from "aurelia-store";
import { IUser } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";

@autoinject()
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class EditUserDialog {
    controller: DialogController;

    oldUser: IUser;
    newUser: IUser;

    showErrorMessage: boolean = false;
    darkUi: boolean;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    projectNameChanged(newName: string, oldName: string) {
        if (newName.length > 0) this.showErrorMessage = false;
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    activate(user: IUser) {
        this.oldUser = Object.assign({}, user);
        this.newUser = Object.assign({}, user);
    }

    save() {
        this.controller.ok(this.newUser);
    }

    cancel() {
        this.controller.cancel();
    }
}
