import { pluck } from "rxjs/operators";
import {
    autoinject,
    bindable,
    bindingMode,
} from "aurelia-framework";
import { SelectDongles } from "./select-dongles";
import { connectTo } from "aurelia-store";
import { byteArrayDownloadUtility } from "resources/download-helper";
import { DialogController, DialogService } from "aurelia-dialog";
import License from "components/license";
import { DataService } from "services/data-service";
import { NewSoftwareKeyUser } from "modals/new-software-key-user";
import {
    SoftwareKeyProducts,
} from "components/license";
import Swal from "sweetalert2";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class UpsertLicense {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    license: License;
    newLicense: boolean;
    type: string;
    number: number;
    leaseExpiration: string;
    maintenanceEndDate: string;
    maxNetworkSessions: number;
    comment: string = "";
    division: string = "";
    vehicleLimit: number;
    selectedDongles: number = 0;

    numberOfSeats: string;
    activationPassword: string;
    enabled: boolean;

    updateData: LicenseUpdate = new LicenseUpdate();
    updateResponse: ILicenseUpdateResponse;

    // these will only be used for new licenses
    softwareKeyProducts = [...SoftwareKeyProducts.values()];

    availableDongles: IDongle[] = new Array<IDongle>;
    selectedProduct?: string = null;
    selectedDongle?: IDongle = null;
    //selectedDongles?: IDongle[] = new Array<IDongle>;

    organizations: IOrganization[];
    selectedOrganization: IOrganization;
    organizationSet: boolean = false;

    products: IFeature[];
    features: IFeature[];
    infinityViewOptions: IFeature[] = new Array<IFeature>;

    controller: DialogController;
    _dataService: DataService;
    dialogService: DialogService;


    constructor(controller: DialogController, dialogService: DialogService, dataService: DataService) {
        this.controller = controller;
        this._dataService = dataService;
        this.dialogService = dialogService;
    }

    activate(license: License) {
        this.license = license;
        this.type = this.license.type;
        this.newLicense = this.license.newLicense;
    }

    test(){
    }

    async bind() {
        if (typeof this.license.number !== "undefined") {
            this.number = this.license.number;
        }
        if (typeof this.license.division !== "undefined") {
            this.division = this.license.division;
        }
        if (typeof this.license.comment !== "undefined") {
            this.comment = this.license.comment;
        }
        if (typeof this.license.organizationId !== "undefined") {
            this.organizationSet = true;
            this.selectedOrganization = await this._dataService.getSoftwareKeyCustomerIdFromServerAsync(this.license.organizationId)
        }
        if (this.newLicense === true && typeof this.license.organizationId === "undefined") {
            this.organizations = await this._dataService.getOrganizationsFromServerAsync();
        }
        this.products = this.license.getProducts();
        this.features = this.license.getFeatures();
        if (this.license.licenseConfiguration.MaintenanceEndDate != null){
            this.maintenanceEndDate =
                this.license.licenseConfiguration.MaintenanceEndDate
                .toISOString()
                .split("T")[0];
        }
        this.vehicleLimit = this.license.licenseConfiguration.VehicleLimit;
        if (this.type === "Keylok") {
            if (this.newLicense === true) {
                await this.getAvailableDongles();
            }
            this.infinityViewOptions = this.license.getInfinityViewOptions();
            if (this.license.registerModel.expirationDate != null){
                this.leaseExpiration = 
                this.license.registerModel.expirationDate
                .toISOString()
                .split("T")[0];
            }
            this.maxNetworkSessions =
                this.license.registerModel.maxNetworkSessions;
            this.setActiveInfinityViewOption(
                this.license.getInfinityViewState()
            );
        } else {
            this.numberOfSeats = String(this.license.numberOfSeats);
            this.activationPassword = this.license.activationPassword;
            this.enabled = this.license.licenseConfiguration.Enabled;
        }
    }

    // Check if there are any changes between the current 
    // configuration of the modal and the initial state.
    checkHasChanges(): boolean {
        var hasChanges: boolean = false;

        if (this.newLicense === true) {
            if (
                this.division !== "" ||
                this.comment !== "" ||
                (typeof this.maintenanceEndDate !== "undefined" &&
                this.maintenanceEndDate !== null)
            ){
                hasChanges = true;
            }
            if ( this.type === "Keylok"){
                for (var product of this.products){
                    if(product.enabled === true){
                        hasChanges = true;
                    }
                }
                if (this.getActiveInfinityViewOption() !== "disabled"){
                    hasChanges = true;
                }
                if (
                    (typeof this.leaseExpiration !== "undefined" &&
                    this.leaseExpiration !== null) ||
                    (typeof this.selectedDongle !== "undefined" &&
                    this.selectedDongle !== null)
                ) {
                        hasChanges = true; 
                }
            } else {
                if(typeof this.selectedProduct !== "undefined" &&
                    this.selectedProduct !== null)
                {
                    hasChanges = true; 
                }
            }
        }
        if (!this.license.compareFeatures(this.features)){
            hasChanges = true;
        }
        if (this.type === "Keylok" &&
            this.newLicense !== true){
            if(this.getActiveInfinityViewOption() !== this.license.getInfinityViewState()){
                hasChanges = true;
            }
            if(!this.license.compareProducts(this.products)){
                hasChanges = true;
            }
        } 

        if (typeof this.license.division !== "undefined"
            && this.division !== this.license.division
        ) {
            hasChanges = true;
        }
        if (typeof this.license.comment !== "undefined" &&
            this.comment !== this.license.comment
        ) {
            hasChanges = true; 
        }
        if (this.license.licenseConfiguration.MaintenanceEndDate != null
            && this.maintenanceEndDate !==
            this.license.licenseConfiguration.MaintenanceEndDate
            .toISOString()
            .split("T")[0]
        ){
            hasChanges = true;
        }
        if ( this.vehicleLimit !== this.license.licenseConfiguration.VehicleLimit){
            hasChanges = true;   
        };
        if (this.type === "Keylok") {
            if (this.license.registerModel.expirationDate != null && 
                this.leaseExpiration !== 
                    this.license.registerModel.expirationDate
                    .toISOString()
                    .split("T")[0]
            ){
                hasChanges = true;
            }
        } else {
            if (this.numberOfSeats !== String(this.license.numberOfSeats)){
                hasChanges = true;
            }
        }

        return hasChanges;
    }

    // Updates the internal license object based on changes made within the UI
    // and then sends those changes to the controller to create or update a license
    async update() {
        this.license.setFeatures(this.features);
        this.updateData.comment = this.comment;
        this.updateData.division = this.division
        // Add or Update Keylok license
        if (this.type === "Keylok") {
            if (typeof this.leaseExpiration !== "undefined"){
                this.license.registerModel.expirationDate = new Date(
                    this.leaseExpiration + 'T00:00:00.000Z'
                );
                this.updateData.leaseExpirationDate = this.license.registerModel.expirationDate;
            }
            
            this.license.registerModel.remarks = this.comment;
            this.license.registerModel.maxNetworkSessions =
                this.maxNetworkSessions;
            this.license.setProducts(
                this.products,
                this.license.getActiveFeatures(),
                new Date(this.maintenanceEndDate + 'T00:00:00.000Z'),
                this.vehicleLimit,
                this.getActiveInfinityViewOption()
            );
            this.updateData.actualMaintenanceEndDate = new Date(this.maintenanceEndDate + 'T00:00:00.000Z');
            this.license.registerModel.globalUniqueId = "00000000-0000-0000-0000-000000000000";
            // Create new Keylok License
            if (this.newLicense === true) {
                this.updateData.keylok = this.license;
                this.license.registerModel.companyName = this.selectedOrganization.name;
                this.updateData.numbers = new Array<string>;
                for ( var dongle of this.availableDongles) {
                    if(dongle.selected){
                        this.updateData.numbers.push(dongle.serialNumber);
                    }
                }
                await this.addKeylokLicenseAsync(this.updateData);
            // Update existing Keylok license
            } else {
                if(this.license.batchUpdate){
                    this.updateData.licenseIds = this.license.updateIds;
                } else {
                    this.updateData.licenseIds = new Array<string>(this.license.licenseId);
                }
                this.license.registerModel.companyName = this.license.organizationName;
                this.license.versionNumber += 1;
                this.updateData.keylok = this.license;
                await this.updateKeylokLicenseAsync(this.updateData);
            }
        // Add or update a Software Key license
        } else {
            this.license.licenseConfiguration.MaintenanceEndDate = new Date(
                this.maintenanceEndDate + 'T00:00:00.000Z'
            );
            this.updateData.actualMaintenanceEndDate = this.license.licenseConfiguration.MaintenanceEndDate;
            this.license.licenseConfiguration.VehicleLimit = this.vehicleLimit;
            this.license.numberOfSeats = parseInt(this.numberOfSeats);

            // Create new Software Key license
            if (this.newLicense === true) {
                if (typeof this.selectedOrganization.softwareKeyID === "undefined"){
                    await this.addSoftwareLicenseCustomerDialog(this.selectedOrganization.name, this.selectedOrganization.organizationId);
                    return;
                } else {
                    // Cast Configuration to string for processing on backend
                    //this.license.licenseConfiguration = JSON.stringify(this.license.licenseConfiguration);
                    this.updateData.software = this.license;
                    await this.addSoftwareKeyLicenseAsync(this.updateData);
                }
            // Update Software Key license
            } else {
                //this.license.licenseConfiguration = JSON.stringify(this.license.licenseConfiguration);
                this.updateData.software = this.license;
                this.updateData.licenseIds = new Array<string>(this.license.licenseId);
                this.license.versionNumber += 1;
                await this.updateSoftwareKeyLicenseAsync(
                    this.updateData,
                    this.number
                );
            }
        }
        if( typeof this.updateResponse !== "undefined"){
            if (this.type === "Software" || 
                (this.type === "Keylok" && typeof this.updateResponse.upgradeFile !== "undefined")){ 
                this.upsertSuccessDialog(this.newLicense ? "add" : "update");
            } else {
                this.errorDialog("Server returned bad AUTHORIZE file, check Keylok Server")
            }
        } else {
            this.errorDialog("Empty response");
        }
    }
    

    openSelectDongles(){
        this.dialogService
        .open({
            viewModel: SelectDongles,
            model: this.availableDongles,
            lock: false,
        })
        .whenClosed((response) => {
            if (!response.wasCancelled) {
                this.selectedDongles = response.output;
            } else {
                this.selectedDongles = 0
                for (var dongle of this.availableDongles){
                    if(dongle.selected){
                        this.selectedDongles += 1;
                    }
                }
            }
        });
    }

    async errorDialog(error?: string){
        Swal.fire({
            title: `Error: ${typeof error !== "undefined" ? ": " + error : ""}. Please try the update again.`,
            showDenyButton: true,
            showCancelButton: true,
            showConfirmButton: false,
            denyButtonText: `Close`,
        }).then(async (result) => {
            this.controller.ok();
        }).catch(async function(reason){
            this.controller.ok();
        });
    }

    async addSoftwareLicenseCustomerDialog(companyName: string, companyId: string){
        Swal.fire({
            title: `This organization does not have an associated ID in the
                    SoftwareKey Solo Server would you like to create a new customer?
                    Company Id is ${companyId}
                    Company Name is ${companyName}`,
            showDenyButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#4CBB17",
            confirmButtonText: `Yes, Create`
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.addSoftwareLicenseCustomer();
            }
        });
    }

    async addSoftwareLicenseCustomer(){
        this.selectedOrganization.softwareKeyID = await this._dataService.postNewSoftwareKeyCustomerToServerAsync(
            this.selectedOrganization.name
        );
    }

    stopUpsert(){
        try {
            var hasChanges = this.checkHasChanges()
            if (hasChanges){
                Swal.fire({
                    title: `Would you like to close without finishing?`,
                    showDenyButton: true,
                    showCancelButton: true,
                    showConfirmButton: false,
                    denyButtonText: `Yes, Close`,
                }).then(async (result) => {
                    if (result.isDenied) {
                        this.controller.cancel();
                    }
                });
            } else {
                this.controller.cancel(); 
            }
        } catch (ex){
            Swal.fire({
                title: `An error has occured.`,
                showDenyButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                denyButtonText: `Close`,
            }).then(async (result) => {
                if (result.isDenied) {
                    this.controller.cancel();
                }
            });
        }
    } 

    // Open confirmation dialog for creating a new license, if it is a new 
    // SoftwareKey License, check if the organization has a SoftwareKey orgId
    // and if they don't prompt the user to create one before adding the license.
    async confirmUpsert(operation: string){
        // If the license is of type Keylok, start by checking if there are enabled products,
        // then check if the keylok server is up.
        if (this.type === "Keylok" && (!this.productEnabledCheck() || !(await this.keylokServerStatus()))) {
            return;
        } 
        if (this.newLicense === true && this.type !== "Keylok" &&
            (typeof this.selectedOrganization.softwareKeyID === "undefined" || this.selectedOrganization.softwareKeyID === null)) {
            await this.addSoftwareLicenseCustomerDialog(this.selectedOrganization.name, this.selectedOrganization.organizationId);
        } else {
            Swal.fire({
                title: `Would you like to finish the license ${operation.toLowerCase()}?`,
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: "#4CBB17",
                confirmButtonText: `Yes, ${operation}`,
            }).then(async (result) => {
                if(result.isConfirmed){
                    this.update();
                }
            });
        }
    } 

    // Checks if any products are enabled on a Keylok license
    productEnabledCheck(): boolean{
        var enabled: boolean = false;
        for (var product of this.products){
            if (product.enabled) {
                enabled = true;
            }
        }
        if (this.getActiveInfinityViewOption() === "office" ||  
            this.getActiveInfinityViewOption() === "standard"){
                enabled = true;
        }
        if (enabled){
            return true
        } else {
            Swal.fire({
                title: `There are currently no products enabled on this license. Please enable at least one product to continue.`,
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: `Ok`,
            }).then(async (result) => {
            });
            return false;
        }
    }

    // Check the status of the keylok API
    async keylokServerStatus(): Promise<boolean>{
        if (await this._dataService.getKeylokServerStatus()){
            return true
        } else {
            Swal.fire({
                title: `The Keylok update server is not responding. Please check the server and try again.`,
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: `Ok`,
            }).then(async (result) => {
            });
            return false;
        }
    }

    // Sets which product to be displayed in the edit pane
    setActiveProduct(productName: string) {
        for (var product of this.products) {
            if (product.name === productName) {
                product.active = true;
            } else {
                product.active = false;
            }
        }
    }

    // Controls the behaviour of the infinityView options as they behave like radio buttons
    setActiveInfinityViewOption(optionName: string) {
        for (var option of this.infinityViewOptions) {
            if (option.name === optionName) {
                option.enabled = true;
            } else {
                option.enabled = false;
            }
        }
    }

    // Checks which infinityViewOption is currently selected
    getActiveInfinityViewOption(): string {
        for (var option of this.infinityViewOptions) {
            if (option.enabled === true) {
                return option.name;
            }
        }
    }

    // Gets a list of dongles from the database with no associated LicenseId
    // Used when creating a new License
    async getAvailableDongles() {
        this.availableDongles = await this._dataService.getDonglesFromServerAsync();
    }

    async updateKeylokLicenseAsync(license: LicenseUpdate) {
        this.updateResponse = await this._dataService.updateKeylokLicenseAsync(
            license,
            {
                dongleSerialNumber:
                    this.license.number,
            }
        )
    }
    
    async addKeylokLicenseAsync(license: LicenseUpdate) {
        this.updateResponse = await this._dataService.postNewKeylokLicenseToServerAsync(
            license,
            {
                organizationId:
                    this.selectedOrganization.organizationId
            }
        );
    }

    // Posts the updated license to the License controller which then stores the updated
    // model in the DB
    async updateSoftwareKeyLicenseAsync(license: LicenseUpdate, number: number) { 
        this.updateResponse = await this._dataService.updateSoftwareKeyLicenseAsync(
            license,
            { licenseNumber: number }
        );
    }

    async addSoftwareKeyLicenseAsync(license: LicenseUpdate) {
        this.updateResponse =
            await this._dataService.postNewSoftwareKeyLicenseToServerAsync(
                license,
                {
                    customerId: this.selectedOrganization.softwareKeyID,
                    productId: [...SoftwareKeyProducts].find(
                        ([key, value]) => this.selectedProduct === value
                    )[0],
                    organizationId:
                    this.selectedOrganization.organizationId
                }
            );
    }

    // Closes the modal
    cancel() {
        this.controller.cancel();
    }

    // Displays a success dialog if the update or add operation was successful
    // For Keylok licenses, allows direct download of the authorize files
    // For all licenses gives the option to jump to the details dialog
    upsertSuccessDialog(action: string){
        var isBatch = this.selectedDongles > 1 || (this.license.batchUpdate && this.license.dongleNumbers.length > 1);
        var isKeylok = this.type === "Keylok";
        var confirmed = false;
        Swal.fire({
            title: `The license ${action} was sucessful! Would you like to${ isKeylok && !isBatch? " directly download the new AUTHORIZE file or" : ""} ${ isBatch ? "directly download the new AUTHORIZE files? WARNING: Once the window closes you will lose access to the batch style AUTHORIZE file and will have to access each file individually." : "view the new license details?"}`,
            showDenyButton: isKeylok ? true : false,
            showCancelButton: true,
            showConfirmButton: !isBatch,
            confirmButtonColor: "#4CBB17",
            confirmButtonText: `Show Details`,
            denyButtonColor: `#4985BD`,
            denyButtonText: `Download AUTHORIZE`,
            cancelButtonText: `Close`,
            allowOutsideClick: false,
            preDeny: () => {
                if (isBatch){
                    byteArrayDownloadUtility(this.updateResponse.upgradeFile, `BATCH_AUTHORIZE.zip`);
                } else {
                    byteArrayDownloadUtility(this.updateResponse.upgradeFile, `DONGLE_${typeof this.updateData.numbers !== "undefined" ? this.updateData.numbers[0] : this.license.number }_AUTHORIZE.zip`);
                }
                return false; // Prevent denied
            }
        }).then(async (result) => {
            if(result.isConfirmed && !isBatch){
                if (this.newLicense){
                    this.controller.ok({showDetails: true, licenseId: this.updateResponse.licenseIds[0]})
                } else {
                    this.controller.ok({showDetails: true, licenseId: this.license.licenseId})
                };
            } else {
                this.controller.ok({showDetails: false});
            }
        })
    }
}

class IOrganization {
    organizationId: string;
	name?: string;
    softwareKeyID?: string;
}

class IFeature {
    name: string;
    label: string;
    enabled: boolean;
    active?: boolean;
}

interface IDongle {
    dongleId: string;
    serialNumber: string;
    selected: boolean;
}

interface ILicenseUpdateResponse{
    licenseIds:  string;
    upgradeFile?: string;
    failedDongles?: string[];
}

class LicenseUpdate{
    licenseIds?: string[];
    numbers: string[];
    keylok?: License;
    software?: License;
    comment?: string;
    division?: string;
    actualMaintenanceEndDate?: Date;
    leaseExpirationDate?: Date;
}
