import { pluck } from "rxjs/operators";
import { IAsset, IFleet, IOrganization, IProject } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { connectTo } from "aurelia-store";
import { StateManager } from "services/state-manager";

@autoinject
@connectTo({
    selector: {
        assets: (store) => store.state.pipe(pluck("assets")),
        projects: (store) => store.state.pipe(pluck("projects")),
        organization: (store) => store.state.pipe(pluck("organization")),
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class FleetDialog {
    controller: DialogController;
    stateManager: StateManager;

    newFleet: IFleet = {
        name: "",
        vessels: [],
        projects: [],
    };
    assets: IAsset[];
    unselectedAssets: IAsset[];
    darkUi: boolean;

    projects: IProject[];
    unselectedProjects: IProject[];
    // organization: IOrganization;
    organizationId: string;

    addingFleet: boolean;

    showWarnings: boolean = false;

    constructor(controller: DialogController, stateManager: StateManager) {
        this.controller = controller;
        this.stateManager = stateManager;
    }

    attached() {
        this.stateManager.getAssetsAsync({
            organizationId: this.organizationId,
        });
        this.stateManager.getProjectsAsync({
            organizationId: this.organizationId,
        });
    }

    activate(organizationId) {
        this.organizationId = organizationId;
    }

    assetsChanged(newAssets, oldAssets) {
        if (newAssets == null || newAssets.length == 0) return;

        if (newAssets == oldAssets) return;

        this.assets = newAssets;
        if (newAssets && newAssets.length > 0) this.getUnselectedAssets();
    }
    projectsChanged(newProjects, oldProjects) {
        if (newProjects == null || newProjects.length == 0) return;

        if (newProjects == oldProjects) return;

        this.projects = newProjects;
        if (newProjects && newProjects.length > 0) this.getUnselectedProjects();
    }
    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    cancel() {
        this.controller.close(false);
    }

    async addNewFleet() {
        if (
            (this.newFleet.name == null || this.newFleet.name == "") &&
            this.showWarnings == false
        ) {
            this.showWarnings = true;
            return;
        }

        this.addingFleet = true;
        this.newFleet.organizationId = this.organizationId;
        await this.stateManager.postFleetAsync(this.newFleet, {
            organizationId: this.organizationId,
        });

        this.addingFleet = false;
        this.controller.ok(true);
    }

    getUnselectedAssets() {
        this.unselectedAssets = this.assets.filter((asset) => {
            return !this.newFleet.vessels.some(
                (v) => v.assetId === asset.assetId
            );
        });
    }
    getUnselectedProjects() {
        this.unselectedProjects = this.projects.filter((project) => {
            return !this.newFleet.projects.some(
                (p) => p.projectId === project.projectId
            );
        });
    }

    addAssetToFleet(asset) {
        this.newFleet.vessels.push(asset);
        this.getUnselectedAssets();
    }

    removeAssetFromFleet(asset) {
        this.newFleet.vessels = this.newFleet.vessels.filter(
            (v) => v.assetId != asset.assetId
        );
        this.getUnselectedAssets();
    }

    addProjectToFleet(project) {
        this.newFleet.projects.push(project);
        this.getUnselectedProjects();
    }

    removeProjectFromFleet(project) {
        this.newFleet.projects = this.newFleet.projects.filter(
            (v) => v.projectId !== project.projectId
        );
        this.getUnselectedProjects();
    }
}
