import { pluck } from "rxjs/operators";
import { IProject } from "models/interfaces";
import {
    autoinject,
    bindable,
    bindingMode,
    observable,
} from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { DialogController } from "aurelia-dialog";
import { State } from "services/state";
import License from "components/license";
import { DataService } from "services/data-service";


@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class NewSoftwareKeyUser {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    companyId: string;
    companyName: string;


    controller: DialogController;
    _dataService: DataService;

    constructor(controller: DialogController, dataService: DataService) {
        this.controller = controller;
        this._dataService = dataService;
    }

    activate(companyInfo: any) {
        this.companyId = companyInfo.companyId;
        this.companyName = companyInfo.companyName;
    }

    bind() {
    }

    async createSoftwareKeyCustomer(){
        var result =
        await this._dataService.postNewSoftwareKeyCustomerToServerAsync(
            this.companyName
        );
    }

    // Closes the modal
    cancel() {
        this.controller.cancel();
    }
}

