import { pluck } from "rxjs/operators";
import { connectTo } from "aurelia-store";
import { AuthenticationService } from "./../services/authentication-service";
import { autoinject, TaskQueue } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { StateManager } from "services/state-manager";
import { IVideo } from "models/interfaces";
import moment from "moment-timezone";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import * as FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import Player from "video.js/dist/types/player";

@autoinject
@connectTo({
	selector: {
		darkUi: (store) => store.state.pipe(pluck("darkUi")),
	},
})
export class RecordingsModal {
	_controller: DialogController;
	_stateManager: StateManager;
	_authenticationService: AuthenticationService;
	_taskQueue: TaskQueue;

	video: IVideo;

	darkUi: any;

	recordings: Array<any>;
	recording: any = null;

	isEditing: boolean = false;
	startTimeflatPickerObject: any;
	endTimeFlatPickerObject: any;

	lengthOfSegmentMinutes: number = 5;
	videoRetentionDays: number = 30;
	isRecording: boolean = true;

	isGeneratingRecording: boolean = false;
	recordingStartTime: Date = null;
	recordingEndTime: Date = null;
	fetchingRecordingFromServer: boolean = false;

	viewingSnippetVideo: boolean = false;
	snippetVideoUrl: string = "";
	snippetVideoResolution: string = "Low";
	snippetVideoLength: number = null;
	isGenerationInProgress: boolean = false;
	generationMessage: string = "";
	videoJs: Player;
	newRecordingsPollingInterval: any;


	constructor(
		controller: DialogController,
		stateManager: StateManager,
		authenticationService: AuthenticationService,
		taskQueue: TaskQueue
	) {
		this._controller = controller;
		this._stateManager = stateManager;
		this._authenticationService = authenticationService;
		this._taskQueue = taskQueue;
	}

	activate(video: IVideo) {
		this.video = video;

		this.fetchRecordingsAsync();

		window.setInterval(() => {
			this.fetchRecordingsAsync();
		}, 3000);
	}

	async fetchRecordingsAsync() {
		const returnedData = await fetch(
			`recordings/getRecordings`,
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${this._authenticationService.session.token}`,
				},
			}
		);
		const recordings = await returnedData.json();


		if (this.newRecordingsPollingInterval != null) {
			if (recordings.length != this.recordings.length) {

				clearInterval(this.newRecordingsPollingInterval);
				this.newRecordingsPollingInterval = null;
				await this.fetchRecordingsAsync();
				this.isGenerationInProgress = false;
				this.generationMessage = "";


			}
		}

		this.recordings = recordings;
	}

	darkUiChanged(newValue, oldValue) {
		this.darkUi = newValue;
	}

	close() {
		if (this.videoJs != null) {
			this.videoJs.reset();
			this.videoJs.dispose();
		}

		this._controller.close(true);
	}

	async save() {
		//Save the settings
		this.isEditing = false;
	}

	async playRecording(recording) {
		const returnedData = await fetch(
			`recordings/GetRecording/${recording.vodId}`,
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${this._authenticationService.session.token}`,
				},
			}
		);
		const jsonUnpackedData = await returnedData.json();
		this.recording = jsonUnpackedData;

		this._taskQueue.queueMicroTask(() => {
			this.createVideoPlayer();
		});
	}

	createVideoPlayer() {

		// if (this.videoJs != null) {
		// 	this.videoJs.dispose();
		// }


		var setup = {
			// techOrder: ["html5", "flash"],
			controls: true,
			preload: "auto",

			children: {
				controlBar: {
					fullscreenToggle: true,
				},
			},
		};


		videojs.setFormatTime((seconds) => {
			if (this.recording == null) return moment().format("MMM Do YY, h:mm:ss a");

			if (this.recording.startDate == null) return moment().format("MMM Do YY, h:mm:ss a");

			var timeStamp = moment(this.recording.startDate).add(seconds, "seconds").format("MMM Do YY, h:mm:ss a")

			return timeStamp;
		})

		const player = videojs(`recorded-snippet-video`, setup);

		this.videoJs = player;

		this.videoJs.src({ type: 'video/mp4', src: this.snippetVideoUrl });

		player.load();
		player.play();
	}

	getLength() {
		const startMoment = moment(this.recordingStartTime);
		const endMoment = moment(this.recordingEndTime);

		return Math.round(
			moment.duration(endMoment.diff(startMoment)).asHours()
		);
	}

	formatDate(date) {
		if (date == null) return "";

		return moment(date).format("MMM Do YY, h:mm:ss a");
	}

	generateRecording() {
		this.isGeneratingRecording = true;
		this.createDatePickers();
	}

	createDatePickers() {
		this._taskQueue.queueMicroTask(() => {
			var startDateElement = document.getElementById("start-date-picker");

			if (startDateElement == null) return;

			if (this.startTimeflatPickerObject != null) return;

			this.startTimeflatPickerObject = new (<any>FlatPickr)(
				startDateElement,
				{
					altInput: true,
					altFormat: "Y-m-d H:i",
					enableTime: true,
					minDate: moment().subtract(30, "days").toDate(),
					maxDate: moment().toDate(),
					dateFormat: "Y-m-d H:i",
					onChange: (newDate) => {
						console.log(newDate[0]);
						this.recordingStartTime = newDate[0];

						// this.recordingStartTime = newDate[0];
					},
					onClose: (selectedDates, dateStr, instance) => {
						// if (this.endTimeFlatPickerObject == null) return;
						// this.endTimeFlatPickerObject.set("minDate", dateStr)
					}
				}
			);


			// var endDateElement = document.getElementById("end-date-picker");

			// if (endDateElement == null) return;

			// if (this.endTimeFlatPickerObject != null) return;

			// this.endTimeFlatPickerObject = new (<any>FlatPickr)(
			// 	endDateElement,
			// 	{
			// 		altInput: true,
			// 		altFormat: "Z",
			// 		dateFormat: "Z",
			// 		enableTime: true,
			// 		maxDate: moment().toDate(),
			// 		onChange: (newDate) => {
			// 			console.log(newDate[0]);
			// 			this.recordingEndTime = newDate[0];
			// 		},
			// 	}
			// );
		});
	}

	async watchRecording(recording) {
		this.recording = recording;
		const recordingSasGenUrl = await this.getRecordingSasGenUrl(recording.name);
		this.snippetVideoUrl = recordingSasGenUrl;
		this.viewingSnippetVideo = true;

		this._taskQueue.queueMicroTask(() => {
			this.createVideoPlayer();
		});
	}
	async downloadRecording(recording) {
		const recordingSasGenUrl = await this.getRecordingSasGenUrl(recording.name);
		try {
			fetch(recordingSasGenUrl)
				.then(response => response.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = `${this.video.name}-${moment(recording.startDate).format("YYYYMMDDhhmmss")}-${moment(recording.endDate).format("YYYYMMDDhhmmss")}.mp4`;
					a.click();
					window.URL.revokeObjectURL(url);
				});
		} catch (error) {
			console.error(error);
		}
	}
	async getRecordingSasGenUrl(recordingName) {
		const returnedData = await fetch(
			`recordings/GetRecording?recordingName=${recordingName}`,
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${this._authenticationService.session.token}`,
				},
			}
		);
		const jsonUnpackedData = await returnedData.json();
		const recording = jsonUnpackedData;
		console.log(recording.filePath);
		return recording.filePath;
	}
	async goBack() {
		if (this.videoJs != null)
			this.videoJs.reset();

		this.viewingSnippetVideo = false;

		this.snippetVideoUrl = "";
		this.recording = null;
		this.isGeneratingRecording = false;
	}
	async startGeneratingSnippetVideo() {
		this.goBack();

		const createdRecordinResponse = await fetch(
			`recordings/CreateRecording`,
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${this._authenticationService.session.token}`,
				},
				method: "POST",
				body: JSON.stringify({
					// videoId: this.video.id,

					startDate: moment(this.recordingStartTime).local().format(),
					endDate: moment.utc(this.recordingEndTime).local().format(),
					resolution: this.snippetVideoResolution
				}),
			}
		);

		const createdRecording = await createdRecordinResponse.json();

		this.fetchRecordingsAsync();

		const generateRecordingResponse = await fetch(
			`recordings/GenerateRecording?recordingId=${createdRecording.recordingId}`,
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${this._authenticationService.session.token}`,
				},
				method: "POST"
			}
		);
	}
	// createVideoPlayer() {
	// 	var options = {};

	// 	var player = videojs('my-player', options, function onPlayerReady() {
	// 		videojs.log('Your player is ready!');

	// 		// In this context, `this` is the player that was created by Video.js.
	// 		this.play();

	// 		// How about an event listener?
	// 		this.on('ended', function () {
	// 			videojs.log('Awww...over so soon?!');
	// 		});
	// 	});
	// }

	roundNumber(duration) {
		return Math.round(duration);
	}

	async deleteRecording(recording) {
		this.recordings = this.recordings.filter(r => r.recordingId != recording.recordingId);

		const returnedData = await fetch(
			`recordings/deleteRecording?recordingId=${recording.recordingId}`,
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${this._authenticationService.session.token}`,
				},
				method: "DELETE"
			}
		);

	}

	calculateEndTime() {
		this.recordingEndTime = moment(this.recordingStartTime).add(this.snippetVideoLength, "minutes").toDate()
	}


}
