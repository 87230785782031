import { pluck } from "rxjs/operators";
import {
    autoinject,
    bindable,
    bindingMode,
} from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { DataService } from "services/data-service";
import { DialogController } from "aurelia-dialog";
import Swal from "sweetalert2";
import { byteArrayDownloadUtility } from "resources/download-helper";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class UpdateMaintenanceDate {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    licenses: LicenseInformation[];
    _dataService: DataService;
    controller: DialogController;
    maintenanceEndDate: Date;
    upgradeResponse: LicenseUpdateResponseDTO;

    constructor(controller: DialogController, dataService: DataService) {
        this.controller = controller;
        this._dataService = dataService;
    }

    activate(licenses: LicenseInformation[]) {
        this.licenses = licenses;
    }

    stopUpdate(){
        this.controller.cancel();
    }

    async confirmUpdate (){
        await Swal.fire({
            title: `Would you like to update the maintenance end date for all selected dongles to: ${this.maintenanceEndDate}?`,
            showDenyButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#4CBB17",
            confirmButtonText: `Yes, Update`,
            denyButtonText: `No, Close`,
        }).then(async (result) => {
            if (result.isConfirmed){
                this.upgradeResponse = await this._dataService.updateKeylokLicenseMaintenanceAsync(
                    {licenses: this.licenses, updateDate : new Date(this.maintenanceEndDate + 'T00:00:00.000Z')});
                var hasFailedDongles = 
                    typeof this.upgradeResponse.failedDongles !== "undefined" 
                    || this.upgradeResponse.failedDongles !== null 
                    || this.upgradeResponse.failedDongles!.length !== 0;
                if (!(typeof this.upgradeResponse["status"] != "undefined" && this.upgradeResponse["status"] != 200)){
                    Swal.fire({
                        title: 
                            `Maintenance dates updated successfully. ${hasFailedDongles ? 'Dongle numbers ' + this.upgradeResponse.failedDongles.toString() + ' failed becuase they did not have existing configurations.' : ''} Would you like to download the corresponding authorize files?`,
                        showDenyButton: true,
                        showCancelButton: false,
                        showConfirmButton: true,
                        denyButtonColor: `#4985BD`,
                        denyButtonText: `Download AUTHORIZE`,
                        confirmButtonColor: "#4CBB17",
                        confirmButtonText: `Close`,
                        allowOutsideClick: false,
                        preConfirm: () => {
                            return this.confirmBatchClose(this.upgradeResponse.upgradeFile);
                        },
                        preDeny: () => {
                            byteArrayDownloadUtility(this.upgradeResponse.upgradeFile, `BATCH_AUTHORIZE.zip`);
                            return false; // Prevent denied
                        }
                    }).then(async (result) => {
                        this.controller.ok();
                    });
                } else {
                    Swal.fire({
                        title: `Something went wrong when trying to update the dates.`,
                        showDenyButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        denyButtonText: `Close`,
                    }).then(async (result) => {});
                }
            }
        });

    }

    // Confirm close of dialog
    confirmBatchClose(file: string): boolean{
        Swal.fire({
            title: `After closing this window you will lose access to the single batch AUTHORIZE file. Select 'Ok' to close the window or 'Download AUTHORIZE' to download the file.`,
            showDenyButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            denyButtonColor: `#4985BD`,
            denyButtonText: `Download AUTHORIZE`,
            confirmButtonText: `Ok`,
            preDeny: () => {
                byteArrayDownloadUtility(file, `BATCH_AUTHORIZE.zip`);
                return false; // Prevent denied
            }
        }).then(async (result) => {
            if(result.isConfirmed){
                this.controller.ok();
                return true;
            } 
            return false;
        });
        return false;
    }
}

interface MaintenanceUpdateDTO{
    licenses: Array<LicenseInformation>;
    updateDate: Date;
}

interface LicenseInformation{
    licenseId: string;
    number:  number;
}

interface LicenseUpdateResponseDTO
{
    licenseIds?: Array<string>;
    upgradeFile?: string;
    failedDongles?: Array<string>;
}