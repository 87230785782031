import { pluck } from "rxjs/operators";
import {
    autoinject,
    observable
} from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { DataService } from "services/data-service";
import { DialogController } from "aurelia-dialog";
import Swal from "sweetalert2";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class AddDongles {
    _dataService: DataService;
    controller: DialogController;
    @observable donglesInput: string = "";
    showValidityCheck: boolean = false;
    validInput: boolean = false;
    addedDongles: number[];
    skippedDongles: number[];
    regexp = new RegExp('^([0-9]+)((,|-)[0-9]+)*$');

    constructor(controller: DialogController, dataService: DataService) {
        this.controller = controller;
        this._dataService = dataService;
    }

    donglesInputChanged(){
        if( this.donglesInput === ""){
            this.showValidityCheck = false;
            this.validInput = false;
        } else {
            this.showValidityCheck = true;
            this.validInput = this.checkInputValidity(this.donglesInput);
        }
    }

    checkInputValidity(input: string): boolean{
        return this.regexp.test(input.replace(/ /g,''));
    }

    stopAddDongle(){
        if(this.donglesInput !== ""){
            Swal.fire({
                title: `Would you like to close without finishing?`,
                showDenyButton: true,
                showCancelButton: true,
                showConfirmButton: false,
                denyButtonText: `Yes, Close`,
            }).then(async (result) => {
                if (result.isDenied) {
                    this.controller.cancel();
                }
            });
        } else {
            this.controller.cancel();
        }
    } 

    async addDongles(input: string){
        var commaSplitArray = input.replace(/ /g,'').split(',');
        var donglesArray = new Array<number>;
        for ( var val of commaSplitArray ){
            if (val.includes("-")){
                var bounds = val.split("-");
                if (Number(bounds[1]) < Number(bounds[0])){
                    Swal.fire({
                        title: `Input includes invalid range`,
                        showDenyButton: true,
                        showCancelButton: true,
                        showConfirmButton: false,
                        denyButtonText: `Close`,
                    }).then(async (result) => {})
                    return
                }
                for (var i = Number(bounds[0]); i <= Number(bounds[1]); i++){
                    donglesArray.push(i);
                }
            } else {
                donglesArray.push(Number(val));
            }
        }

        var donglesSet = [...new Set(donglesArray)].sort();

        await Swal.fire({
            title: `Would you like to add the following dongles to the database: ${donglesSet.join(', ')}?`,
            showDenyButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#4CBB17",
            confirmButtonText: `Yes, Add`,
            denyButtonText: `No, Close`,
        }).then(async (result) => {
            if (result.isConfirmed){
                this.addedDongles = await this._dataService.postDonglesToServerAsync(donglesSet);
                this.addedDongles.sort()
                this.skippedDongles = donglesSet.filter(item => !this.addedDongles.includes(item));
            
                if (typeof this.addedDongles !== "undefined"){
                    Swal.fire({
                        title: 
                            `${ this.addedDongles.length !== 0 ? "Dongle " + this.addedDongles.join(', ') + " added to the database. " : "" }
                            ${ this.skippedDongles.length !== 0 ? "Dongle " + this.skippedDongles.join(', ') + " already in the database." : "" }`,
                        showDenyButton: false,
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonColor: "#4CBB17",
                        confirmButtonText: `Ok`,
                    }).then(async (result) => {
                        if (result.isConfirmed){
                            this.controller.ok();
                        }
                    });
                } else {
                    Swal.fire({
                        title: `Something went wrong when trying to add the dongles to the database.`,
                        showDenyButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        denyButtonText: `Close`,
                    }).then(async (result) => {});
                }
            }
        });
    }
}