import { pluck } from "rxjs/operators";
import { IAsset, IUser } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { StateManager } from "services/state-manager";
import { connectTo } from "aurelia-store";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class DeleteUserDialog {
    controller: DialogController;
    _stateManager: StateManager;

    user: IUser;
    darkUi: boolean;

    constructor(controller: DialogController, stateManager: StateManager) {
        this.controller = controller;
        this._stateManager = stateManager;
    }

    activate(user: IUser) {
        this.user = user;
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    cancel() {
        this.controller.error(false);
    }

    async delete() {
        this.controller.ok(this.user);
    }
}
