import { State } from "./../services/state";
import { pluck } from "rxjs/operators";
import { autoinject, observable, TaskQueue } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { IAsset, IOrganization, IProject, IVideo } from "models/interfaces";
import { connectTo } from "aurelia-store";
import { StateManager } from "services/state-manager";
import { DataService } from "services/data-service";

@autoinject()
@connectTo({
    selector: {
        organizations: (store) => store.state.pipe(pluck("organizations")),
        assets: (store) => store.state.pipe(pluck("assets")),
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class EditVideoModal {
    controller: DialogController;

    _dataService: DataService;

    stateVideo: IVideo;
    newVideo: IVideo;
    state: State;

    organizations: IOrganization[];
    assets: IAsset[];

    organizationProjects: IProject[];

    stateManager: StateManager;
    _taskQueue: TaskQueue;
    darkUi: boolean;

    @observable selectedOrganization: IOrganization;
    selectedAsset: IAsset;

    showWarnings: boolean = false;
    editingStreamId: boolean = false;

    constructor(
        controller: DialogController,
        state: State,
        stateManager: StateManager,
        taskQueue: TaskQueue,
        dataService: DataService
    ) {
        this.controller = controller;
        this.state = state;
        this.stateManager = stateManager;
        this._taskQueue = taskQueue;
        this._dataService = dataService;
    }

    activate(video: IVideo) {
        console.log("Video", video);
        this.stateVideo = structuredClone(video);
        this.newVideo = structuredClone(video);
        if (video.asset) {
            this.selectedAsset = video.asset;
        }
    }

    get streamId() {
        return `srt%3A%2F%2Fome.4dnav.com%3A9999%2Fapp%2F${encodeURIComponent(
            this.newVideo.streamId.replace(/\s/g, "")
        )}`;
    }

    attached() {
        // this.stateManager.getAssetsAsync();
    }

    selectedOrganizationChanged(newValue, oldValue) {
        if (newValue == null) {
            this.selectedAsset = null;
            this.organizationProjects = [];
            return;
        }

        this._dataService
            .getOrganizationAssets(newValue.organizationId)
            .then((vessels) => {
                this.assets = vessels;
                this.getSelectedOrgsAssets();
            });
    }

    organizationsChanged(newOrganizations, oldOrganizations) {
        this.organizations = newOrganizations;
        this.getSelectedOrgsAssets();
    }
    assetsChanged(newAssets, oldAssets) {
        this.assets = newAssets;
        this.getSelectedOrgsAssets();
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    cancel() {
        this.controller.error(this.stateVideo);
    }
    async save() {
        if (this.selectedOrganization) {
            this.newVideo.organizationId =
                this.selectedOrganization.organizationId;
        } else {
            this.newVideo.projectId = null;
            this.newVideo.organizationId = null;
            this.newVideo.organization = null;
        }

        if (this.selectedAsset) {
            this.newVideo.assetId = this.selectedAsset.assetId;
        } else {
            this.newVideo.assetId = null;
        }

        if (
            (this.newVideo.name == null || this.newVideo.name == "") &&
            this.showWarnings == false
        ) {
            this.showWarnings = true;
            return;
        }

        this.controller.ok(this.newVideo);
    }
    getSelectedOrgsAssets() {
        if (
            this.organizations &&
            this.organizations.length > 0 &&
            this.newVideo.organizationId
        )
            this.selectedOrganization = this.organizations.find(
                (o) => o.organizationId == this.newVideo.organizationId
            );

        if (this.assets && this.assets.length > 0 && this.newVideo.vesselId)
            this.selectedAsset = this.assets.find(
                (v) => v.assetId == this.newVideo.assetId
            );
    }
    editStreamId() {
        this.editingStreamId = true;
    }
    saveStreamId() {
        this.editingStreamId = false;
    }
}
