import { pluck } from "rxjs/operators";
import { IAsset } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { StateManager } from "services/state-manager";
import { connectTo } from "aurelia-store";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class DeleteAssetDialog {
    controller: DialogController;
    _stateManager: StateManager;

    asset: IAsset;
    darkUi: boolean;

    constructor(controller: DialogController, stateManager: StateManager) {
        this.controller = controller;
        this._stateManager = stateManager;
    }

    activate(asset: IAsset) {
        this.asset = asset;
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    cancel() {
        this.controller.error(false);
    }

    async delete() {
        this.controller.ok(this.asset);
    }
}
