import { pluck } from "rxjs/operators";
import {
    autoinject,
    bindable,
    bindingMode,
} from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { DialogController } from "aurelia-dialog";

import Swal from "sweetalert2";

/* Ignite UI Imports */
import "igniteui-webcomponents-grids/grids/combined";
import type {
    IgcGridComponent,

} from "igniteui-webcomponents-grids/grids/";
import {
    IgcCellTemplateContext,
    IgcRowSelectionEventArgs,
} from "igniteui-webcomponents-grids/grids";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class SelectDongles {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    availableDongles: IDongle[];
    initialSelection: string[] = new Array<string>;
    controller: DialogController;

    /* Ignite UI Variables */
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    donglesGrid: IgcGridComponent;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    /*Ignite UI Code*/
    async bind() {
        this.donglesGrid.data = this.availableDongles;
    }

    attached(){
        this.donglesGrid.selectRows(this.initialSelection, true);
    }

    activate(availableDongles: IDongle[]) {
        this.availableDongles = availableDongles;
        for(var dongle of this.availableDongles){
            if(dongle.selected){
                this.initialSelection.push(dongle.dongleId);
            }
        }
    }

    stopUpdate(){
        this.controller.cancel();
    }

    async confirmUpdate (){
        for(var dongle of this.availableDongles){
            this.donglesGrid.selectedRows.indexOf(dongle.dongleId) > -1 ? dongle.selected = true : dongle.selected = false;
        }
        this.controller.ok(this.donglesGrid.selectedRows.length);
    }
}


interface IDongle {
    dongleId: string;
    serialNumber: string;
    selected: boolean;
}
