import { pluck } from "rxjs/operators";
import { IAsset, IOrganization, IProject, IUser } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { connectTo } from "aurelia-store";
import { StateManager } from "services/state-manager";

@autoinject
@connectTo({
    selector: {
        projects: (store) => store.state.pipe(pluck("projects")),
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
        users: (store) => store.state.pipe(pluck("users")),
        organization: (store) => store.state.pipe(pluck("organization")),
    },
})
export class EditAssetDialog {
    controller: DialogController;
    _stateManager: StateManager;

    organization: IOrganization;

    changedAsset: IAsset;
    unchangedAsset: IAsset;

    projects: IProject[];
    unselectedProjects: IProject[];

    users: IUser[];
    unlinkedUsers: IUser[];
    linkedUsers: IUser[] = [];

    showWarnings: boolean = false;
    darkUi: boolean;

    constructor(controller: DialogController, stateManager: StateManager) {
        this.controller = controller;
        this._stateManager = stateManager;
    }

    attached() {
        this._stateManager.getProjectsAsync({
            organizationId: this.organization.organizationId,
        });
        this._stateManager.getUsersAsync({
            organizationId: this.organization.organizationId,
        });
    }

    activate(asset: IAsset) {
        this.changedAsset = structuredClone(asset);
        this.unchangedAsset = structuredClone(asset);
    }

    projectsChanged(newProjects, oldProjects) {
        this.projects = newProjects;
        if (newProjects && newProjects.length > 0) this.getUnselectedProjects();
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    async usersChanged(newUsers, oldUsers) {
        this.users = newUsers;
        if (newUsers && newUsers.length > 0) {
            await this.getSelectedUsers();
            await this.getUnselectedUsers();
        }
    }
    organizationChanged(newOrg, oldOrg) {
        this.organization = newOrg;
    }

    cancel() {
        this.controller.cancel(this.unchangedAsset);
    }

    async save() {
        if (
            (this.changedAsset.name == null || this.changedAsset.name == "") &&
            this.showWarnings == false
        ) {
            this.showWarnings = true;
            return;
        }

        this.changedAsset.users = this.linkedUsers;

        await this._stateManager.updateAssetAsync(this.changedAsset);

        this.controller.ok(this.changedAsset);
    }

    getSelectedUsers() {
        this.linkedUsers = this.users.filter((user) => {
            return (
                user.isAdmin ||
                this.changedAsset.users.some((u) => u.userId === user.userId)
            );
        });
    }

    getUnselectedUsers() {
        var unselectedUsers = this.users.filter((user) => {
            return !this.linkedUsers.some((u) => u.userId === user.userId);
        });
        this.unlinkedUsers = unselectedUsers;
    }

    async addUserToAsset(user) {
        if (this.changedAsset.users == null) this.changedAsset.users = [];
        this.changedAsset.users.push(user);

        await this.getSelectedUsers();
        await this.getUnselectedUsers();
    }

    async removeUserFromAsset(user) {
        this.changedAsset.users = this.changedAsset.users.filter(
            (u) => u.userId !== user.userId
        );
        await this.getSelectedUsers();
        await this.getUnselectedUsers();
    }

    getUnselectedProjects() {
        if (this.changedAsset.projects == null) this.changedAsset.projects = [];
        this.unselectedProjects = this.projects.filter((project) => {
            return !this.changedAsset.projects.some(
                (p) => p.projectId === project.projectId
            );
        });
    }

    addProjectToAsset(project) {
        this.changedAsset.projects.push(project);
        this.getUnselectedProjects();
    }

    removeProjectFromAsset(project) {
        this.changedAsset.projects = this.changedAsset.projects.filter(
            (p) => p.projectId !== project.projectId
        );
        this.getUnselectedProjects();
    }
}
