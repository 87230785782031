import { pluck } from "rxjs/operators";
import {
    IAsset,
    IAssetStatus,
    IOrganization,
    IProject,
    IUser,
    IVideo,
} from "models/interfaces";
import { autoinject, observable } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { StateManager } from "services/state-manager";
import { connectTo } from "aurelia-store";
import { DataService } from "services/data-service";

@autoinject()
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
        organizations: (store) => store.state.pipe(pluck("organizations")),
        assets: (store) => store.state.pipe(pluck("assets")),
    },
})
export class NewVideoDialog {
    controller: DialogController;
    _dataService: DataService;
    newVideo: IVideo = {
        videoId: null,
        name: "",
        status: "",
        thumbnail: "",
        organizationId: null,
        streamId: "",
        playListStatus: "",
        type: "",
        publishType: "",
    };
    stateManager: StateManager;
    addingVideo: boolean = false;

    showWarnings: boolean = false;
    darkUi: boolean;

    organizations: IOrganization[];
    assets: IAsset[];

    @observable selectedOrganization: IOrganization;
    selectedAsset: IAsset;

    organizationProjects: IProject[];

    constructor(
        controller: DialogController,
        stateManager: StateManager,
        dataService: DataService
    ) {
        this.controller = controller;
        this.stateManager = stateManager;
        this._dataService = dataService;
    }

    attached() {}

    activate() {}

    organizationsChanged(newOrganizations, oldOrganizations) {
        this.organizations = newOrganizations;
        this.getSelectedOrgsAssets();
    }
    assetsChanged(newAssets, oldAssets) {
        this.assets = newAssets;
        this.getSelectedOrgsAssets();
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    cancel() {
        this.controller.close(false);
    }

    get streamId() {
        return `srt%3A%2F%2Fome.4dnav.com%3A9999%2Fapp%2F${encodeURIComponent(
            this.newVideo.name.replace(/\s/g, "")
        )}`;
    }

    async addNewVideo() {
        if (
            (this.newVideo.name == null || this.newVideo.name == "") &&
            this.showWarnings == false
        ) {
            this.showWarnings = true;
            return;
        }

        //this.newVideo.organizationId = this.organizationId;

        this.newVideo.streamId = this.newVideo.name.replace(/\s/g, "");
        this.newVideo.organizationId = this.selectedOrganization.organizationId;
        this.newVideo.assetId = this.selectedAsset.assetId;

        this.addingVideo = true;
        await this.stateManager.postVideoAsync(this.newVideo);
        this.addingVideo = false;
        this.controller.ok(true);
    }

    getSelectedOrgsAssets() {
        if (
            this.organizations &&
            this.organizations.length > 0 &&
            this.newVideo.organizationId
        )
            this.selectedOrganization = this.organizations.find(
                (o) => o.organizationId == this.newVideo.organizationId
            );

        if (this.assets && this.assets.length > 0 && this.newVideo.vesselId)
            this.selectedAsset = this.assets.find(
                (v) => v.assetId == this.newVideo.assetId
            );
    }

    selectedOrganizationChanged(newValue, oldValue) {
        if (newValue == null) {
            this.selectedAsset = null;
            this.organizationProjects = [];
            return;
        }

        this._dataService
            .getOrganizationAssets(newValue.organizationId)
            .then((vessels) => {
                this.assets = vessels;
                this.getSelectedOrgsAssets();
            });
    }
}
