import { pluck } from "rxjs/operators";
import { IAsset, IAssetStatus, IProject, IUser } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { StateManager } from "services/state-manager";
import { connectTo } from "aurelia-store";

@autoinject()
@connectTo({
    selector: {
        projects: (store) => store.state.pipe(pluck("projects")),
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
        users: (store) => store.state.pipe(pluck("users")),
    },
})
export class NewAssetDialog {
    controller: DialogController;
    newAsset: IAsset = {
        name: "",
        latitude: 0,
        longitude: 0,
        height: 0,
        heading: 0,
        pitch: 0,
        roll: 0,
        fleets: [],
        videos: [],
        projects: [],
        users: [],
        status: IAssetStatus.Complete,
        modelUrl: "",
        mapStatus: "Loaded",
        type: null,
        group: null,
        organizationId: null,
    };
    stateManager: StateManager;
    addingAsset: boolean = false;

    projects: IProject[];
    unselectedProjects: IProject[];
    organizationId: string;

    users: IUser[];
    unlinkedUsers: IUser[];
    linkedUsers: IUser[] = [];

    showWarnings: boolean = false;
    darkUi: boolean;

    constructor(controller: DialogController, stateManager: StateManager) {
        this.controller = controller;
        this.stateManager = stateManager;
    }

    attached() {
        this.stateManager.getProjectsAsync({
            organizationId: this.organizationId,
        });
        this.stateManager.getUsersAsync({
            organizationId: this.organizationId,
        });
    }

    activate(organizationId) {
        this.organizationId = organizationId;
    }

    projectsChanged(newProjects, oldProjects) {
        this.projects = newProjects;
        if (newProjects && newProjects.length > 0) this.getUnselectedProjects();
    }
    async usersChanged(newUsers, oldUsers) {
        this.users = newUsers;
        if (newUsers && newUsers.length > 0) {
            await this.getSelectedUsers();
            await this.getUnselectedUsers();
        }
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    getUnselectedProjects() {
        if (this.newAsset.projects == null) this.newAsset.projects = [];
        this.unselectedProjects = this.projects.filter((project) => {
            return !this.newAsset.projects.some(
                (p) => p.projectId === project.projectId
            );
        });
    }

    cancel() {
        this.controller.close(false);
    }

    async addNewAsset() {
        if (
            (this.newAsset.name == null || this.newAsset.name == "") &&
            this.showWarnings == false
        ) {
            this.showWarnings = true;
            return;
        }

        this.newAsset.organizationId = this.organizationId;

        this.addingAsset = true;
        await this.stateManager.postAssetAsync(this.newAsset, {
            organizationId: this.organizationId,
        });
        this.addingAsset = false;
        this.controller.ok(true);
    }

    addProjectToAsset(project) {
        this.newAsset.projects.push(project);
        this.getUnselectedProjects();
    }

    removeProjectFromAsset(project) {
        this.newAsset.projects = this.newAsset.projects.filter(
            (v) => v.projectId !== project.projectId
        );
        this.getUnselectedProjects();
    }

    async getSelectedUsers() {
        this.linkedUsers = this.users.filter((user) => {
            return (
                user.isAdmin ||
                this.newAsset.users.some((u) => u.userId === user.userId)
            );
        });
    }

    async getUnselectedUsers() {
        var unselectedUsers = this.users.filter((user) => {
            return !this.linkedUsers.some((u) => u.userId === user.userId);
        });
        this.unlinkedUsers = unselectedUsers;
    }

    async addUserToAsset(user) {
        if (this.newAsset.users == null) this.newAsset.users = [];
        this.newAsset.users.push(user);

        await this.getSelectedUsers();
        await this.getUnselectedUsers();
    }

    async removeUserFromAsset(user) {
        this.newAsset.users = this.newAsset.users.filter(
            (u) => u.userId !== user.userId
        );
        await this.getSelectedUsers();
        await this.getUnselectedUsers();
    }
}
