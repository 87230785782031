export enum IAssetGroup {
	Unknown,
	Vehicles,
	Structures,
	Jumpers,
	AisVessels,
	Pipelines,
}

export interface IAsset {
	assetId?: string;
	organizationId?: string;
	projectId?: string;
	name: string;
	latitude: number;
	longitude: number;
	height: number;
	heading: number;
	pitch: number;
	roll: number;
	status: IAssetStatus;
	modelUrl: string;
	mapStatus: "Loaded" | "Missing Asset";
	type: IAssetType;
	group: IAssetGroup;
	isVisible?: boolean;
	fleets?: Array<IFleet>;
	videos?: Array<IVideo>;
	projects?: Array<IProject>;
	users?: Array<IUser>;
}

export interface IUser {
	userId?: string;
	firstName: string;
	lastName: string;
	email: string;
	roleDropdownOpen?: boolean;
	role?: string;
	organization?: IOrganization;
}

export interface IOrganization {
	organizationId: string;
	name: string;
	logoUrl: string;
	iconUrl: string;
	softwareRepositorynUrl: string;
	projects?: Array<IProject>;
	users?: Array<IUser>;
	barColor: string;
	barTextColor: string;
}

export interface IProject {
	projectId?: string;
	organizationId: string;
	name: string;

	xLightingCoordinate: number;
	yLightingCoordinate: number;
	zLightingCoordinate: number;
	lightingIntensity: number;

	xOriginCoordinate: number;
	yOriginCoordinate: number;
	defaultZoomLevel: number;
	initialProjectionEPSGCode: string;

	// videos?: Array<IVideo>;
	assets?: Array<IAsset>;
	fleets?: Array<IFleet>;
}

export interface IVideo {
	videoId: string;
	projectId?: string;
	videoStatus?: string;
	organizationId?: string;
	assetId?: string;
	vesselId?: string;

	thumbnail: string;
	title?: string;
	description?: string;

	streamId: string;
	status: string;
	playListStatus: string;
	type: string;
	publishType: string;
	name: string;

	publish?: boolean;
	date?: Date;
	duration?: number;
	category?: string;
	speed?: number;
	streamUrl?: string;
	expireDurationMs?: number;
	latitude?: string;
	longitude?: string;
	altitude?: string;
	metaData?: string;
	updateTime?: number;
	streamToken?: string;

	organizationName?: string;
	projectName?: string;
	assetName?: string;
	organization?: IOrganization;
	asset?: IAsset;

	isPlaying?: boolean;
	videoJs?: any;
	restartPollerId?: number;
	isActive?: boolean;

	omePlayer?: any;
	server?: string;
	reloadTimer?: any;
}

export interface IFleet {
	fleetId?: string;
	name: string;
	vessels: Array<IAsset>;
	projects?: Array<IProject>;
	organizationId?: string;
}

export interface IUser {
	userId?: string;
	organizationId?: string;
	firstName: string;
	lastName: string;
	email: string;
	mobilePhone: number;
	isAdmin: boolean;
	userVideoState: IUserVideoState;
	assets?: IAsset[];
	projects?: IProject[];
	fleets?: IFleet[];
	videos?: IVideo[];
}

export interface IUserVideoState {
	playingVideos: string;
	filterState: string;
	isVideoThumbnailTrayOpen: boolean;
}

/***********************/
/* OLD MAPPING CLASSES */
/***********************/

export interface ITerrain {
	terrainId: string;
	name: string;
	url: string;
	colorInHex: string;
	dimensionType: IDimensionType;

	minZoom: number;
	maxZoom: number;

	isHidden: boolean;
	isProjectLoaded: boolean;

	terrainGroupName: string;
}
export enum IDimensionType {
	ThreeD,
	TwoD,
}
export enum IAssetStatus {
	Planned,
	Installing,
	Installed,
	Testing,
	Tested,
	Complete,
}
export enum IAssetType {
	Model,
	Polyline,
}

export interface IAisVessel {
	shipName: string;
	latitude: number;
	longitude: number;
	heading: number;
	course: number;
	speed: number;
	timeStamp: number;
	status: string;
	length: number;
	width: number;
	toBow: number;
	toStern: number;
	toPort: number;
	toStarboard: number;
	url3D: string;
	tileSet: any;
	videosExpanded?: boolean;
}
export interface IAisData {
	shipName: string;
	latitude: number;
	longitude: number;
	heading: number;
	course: number;
	speed: number;
	timeStamp: number;
	status: string;
	length: number;
	width: number;
	toBow: number;
	toStern: number;
	toPort: number;
	toStarboard: number;
	url3D: string;
	tileSet: any;
	videosExpanded?: boolean;
	vides: Array<any>;
}

export interface IPolyline {
	polylineId: string;
	name: string;
	diameter: number;
	color: string;
	coordinateUrl: string;
	isVisible: boolean;
}

export interface IWaypoint {
	waypointId?: string;
	name: string;
	description: string;
	category: string;
	depth: number;
	latitude: number;
	longitude: number;
	order: number;
	dateCreated?: Date;
	isActive: boolean;
	color: string;
	symbol?: string;
}

export interface ISurveyLine {
	surveyLineId?: string;
	projectId?: string;
	name: string;
	description: string;
	color: string;
	project?: IProject;
	waypoints: Array<IWaypoint>;
	dateCreated?: Date;
}

export interface IExtent {
	minX: number;
	minY: number;
	maxX: number;
	maxY: number;
}
export interface ICoordinate {
	x: number;
	y: number;
}

export interface IPanel {
	type: string;
	data: any;
	title: string;
	dataUpdatedFunction?: Function;
	icon: string;
}

export interface IAnchorState {
	AnchorId: string;
	AnchorPosition: IAnchorPosition;
	AnchorState: number;
	FairleadPosition: IAnchorPosition;
	MidlineBuoys: string
	SenderId: string;
	TargetPosition: IAnchorPosition;
}

export interface IAnchorPosition {
	CrsCode: number;
	Lat: { Degrees: number };
	Lon: { Degrees: number };
}
