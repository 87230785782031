import { pluck } from "rxjs/operators";
import { IFleet, IProject, IAsset, IOrganization } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { connectTo } from "aurelia-store";
import { StateManager } from "services/state-manager";

@autoinject
@connectTo({
    selector: {
        assets: (store) => store.state.pipe(pluck("assets")),
        projects: (store) => store.state.pipe(pluck("projects")),
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
        organization: (store) => store.state.pipe(pluck("organization")),
    },
})
export class EditFleetDialog {
    controller: DialogController;
    _stateManager: StateManager;

    changedFleet: IFleet;
    unchangedFleet: IFleet;

    assets: IAsset[];
    unselectedAssets: IAsset[];

    projects: IProject[];
    unselectedProjects: IProject[];

    organization: IOrganization;

    showWarnings: boolean = false;
    darkUi: boolean;

    constructor(controller: DialogController, stateManager: StateManager) {
        this.controller = controller;
        this._stateManager = stateManager;
    }

    attached() {
        if (this.organization) {
            this._stateManager.getAssetsAsync({
                organizationId: this.organization.organizationId,
            });
            this._stateManager.getProjectsAsync({
                organizationId: this.organization.organizationId,
            });
        }
    }

    activate(fleet: IFleet) {
        this.changedFleet = structuredClone(fleet);
        this.unchangedFleet = structuredClone(fleet);
    }

    assetsChanged(newAssets, oldAssets) {
        if (newAssets == null || newAssets.length == 0) return;

        if (newAssets == oldAssets) return;

        this.assets = newAssets;
        if (newAssets && newAssets.length > 0) this.getUnselectedAssets();
    }

    projectsChanged(newProjects, oldprojects) {
        if (newProjects == null || newProjects.length == 0) return;

        if (newProjects == oldprojects) return;

        this.projects = newProjects;
        if (newProjects && newProjects.length > 0) this.getUnselectedProjects();
    }
    organizationChanged(newOrg, oldOrg) {
        this.organization = newOrg;
        if (this.assets == null || this.assets.length == 0) {
            this._stateManager.getAssetsAsync({
                organizationId: this.organization.organizationId,
            });
        }
        if (this.projects == null || this.projects.length == 0) {
            this._stateManager.getProjectsAsync({
                organizationId: this.organization.organizationId,
            });
        }
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    cancel() {
        this.controller.error(this.unchangedFleet);
    }

    async save() {
        if (
            (this.changedFleet.name == null || this.changedFleet.name == "") &&
            this.showWarnings == false
        ) {
            this.showWarnings = true;
            return;
        }

        this.controller.ok(this.changedFleet);
    }

    getUnselectedAssets() {
        if (this.changedFleet.vessels == null) this.changedFleet.vessels = [];
        this.unselectedAssets = this.assets.filter((asset) => {
            return !this.changedFleet.vessels.some(
                (v) => v.assetId === asset.assetId
            );
        });
    }

    getUnselectedProjects() {
        if (this.changedFleet.projects == null) this.changedFleet.projects = [];
        this.unselectedProjects = this.projects.filter((project) => {
            return !this.changedFleet.projects.some(
                (p) => p.projectId === project.projectId
            );
        });
    }

    addAssetToFleet(asset) {
        this.changedFleet.vessels.push(asset);
        this.getUnselectedAssets();
    }
    removeAssetFromFleet(asset) {
        this.changedFleet.vessels = this.changedFleet.vessels.filter(
            (v) => v.assetId !== asset.assetId
        );
        this.getUnselectedAssets();
    }

    addProjectToFleet(project) {
        this.changedFleet.projects.push(project);
        this.getUnselectedProjects();
    }

    removeProjectFromFleet(project) {
        this.changedFleet.projects = this.changedFleet.projects.filter(
            (p) => p.projectId !== project.projectId
        );
        this.getUnselectedProjects();
    }
}
