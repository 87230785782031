import { pluck } from "rxjs/operators";
import { IAsset, IProject, IUser } from "models/interfaces";
import { autoinject, observable } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import { connectTo } from "aurelia-store";

@autoinject()
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class NewUserDialog {
    controller: DialogController;
    _authenticationService: AuthenticationService;

    newUser: IUser;
    organizationId: string;
    darkUi: boolean;

    @observable firstName: string = "";
    @observable lastName: string = "";
    @observable email: string = "";
    @observable phoneNumber: string = "";
    @observable password: string = "";
    @observable repeatPassword: string = "";

    showingErrors: boolean = false;
    firstNameError: string = null;
    lastNameError: string = null;
    emailError: string = null;
    phoneNumberError: string = null;
    passwordError: string = null;
    repeatPasswordError: string = null;

    constructor(
        controller: DialogController,
        authenticationService: AuthenticationService
    ) {
        this.controller = controller;
        this._authenticationService = authenticationService;
    }

    activate(organizationId) {
        this.organizationId = organizationId;
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    firstNameChanged(newValue, oldValue) {
        if (newValue === "" || newValue === null) {
            this.firstNameError = "First name is required";
            return;
        }
        this.firstNameError = null;
    }

    lastNameChanged(newValue, oldValue) {
        if (newValue === "" || newValue === null) {
            this.lastNameError = "Last name is required";
            return;
        }
        this.lastNameError = null;
    }

    emailChanged(newValue, oldValue) {
        if (newValue === "" || newValue === null) {
            this.emailError = "Username is required";
            return;
        }
        this.emailError = null;
    }

    phoneNumberChanged(newValue, oldValue) {
        if (newValue === "" || newValue === null) {
            this.phoneNumberError = "Phone number is required";
            return;
        }
        if (newValue.length != 10) {
            this.phoneNumberError = "Please enter 10-digit number";
            return;
        }
        this.phoneNumberError = null;
    }

    passwordChanged(newValue, oldValue) {
        if (newValue === "" || newValue === null) {
            this.passwordError = "Password is required";
            return;
        }
        this.passwordError = null;
    }

    repeatPasswordChanged(newValue, oldValue) {
        if (newValue === "" || newValue === null) {
            this.repeatPasswordError = "Repeat password is required";
            return;
        }
        if (newValue != this.password) {
            this.repeatPasswordError = "Passwords must match";
            return;
        }
        this.repeatPasswordError = null;
    }

    async save() {
        if (this.firstName == null || this.firstName == "") {
            this.firstNameError = "First name is required";
        }
        if (this.lastName == null || this.lastName == "") {
            this.lastNameError = "Last name is required";
        }
        if (this.email == null || this.email == "") {
            this.emailError = "Username is required";
        }
        if (this.phoneNumber == null || this.phoneNumber == "") {
            this.phoneNumberError = "Phone number is required";
        }
        if (this.password == null || this.password == "") {
            this.passwordError = "Password is required";
        }
        if (this.repeatPassword == null || this.repeatPassword == "") {
            this.repeatPasswordError = "Repeat password is required";
        }

        if (
            this.firstNameError ||
            this.lastNameError ||
            this.emailError ||
            this.phoneNumberError ||
            this.passwordError ||
            this.repeatPasswordError
        ) {
            this.showingErrors = true;
            return;
        }

        var registerResult = await this._authenticationService.registerAsync(
            this.firstName,
            this.lastName,
            this.email,
            parseInt(this.phoneNumber, 10),
            this.password,
            this.repeatPassword,
            this.organizationId
        );

        if (registerResult == "Email Taken") {
            this.emailError = "Username is already in use";
            return;
        }

        this.controller.ok(this.newUser);
    }

    cancel() {
        this.controller.cancel();
    }
}
