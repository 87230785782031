import { pluck } from "rxjs/operators";
import { IProject } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { connectTo } from "aurelia-store";

@autoinject()
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class DeleteProjectDialog {
    controller: DialogController;
    project: IProject;

    confirmingDelete: boolean = false;
    deleteCheck: string = "";
    darkUi: boolean;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    activate(project: IProject) {
        this.project = project;
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    delete() {
        if (this.deleteCheck == "DELETE") this.controller.ok(true);
    }

    cancel() {
        this.controller.cancel();
    }
}
