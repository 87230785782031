import { pluck } from "rxjs/operators";
import { connectTo } from "aurelia-store";
import { IAsset, IProject } from "models/interfaces";
import { autoinject, observable } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";

@autoinject()
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class EditProjectDialog {
    controller: DialogController;

    project: IProject;

    @observable projectName: string;

    showErrorMessage: boolean = false;
    darkUi: boolean;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    projectNameChanged(newName: string, oldName: string) {
        if (newName.length > 0) this.showErrorMessage = false;
    }
    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    activate(project: IProject) {
        this.project = project;
        this.projectName = project.name;
    }

    save() {
        if (this.projectName == "") {
            this.showErrorMessage = true;
            return;
        }
        this.project.name = this.projectName;
        this.controller.ok(this.project);
    }

    cancel() {
        this.controller.cancel();
    }
}
