import { pluck } from "rxjs/operators";
import {
    autoinject,
    bindable,
    bindingMode,
} from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { DataService } from "services/data-service";
import { DialogController } from "aurelia-dialog";
import Swal from "sweetalert2";
import { byteArrayDownloadUtility } from "resources/download-helper";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class UpdateLicenseOrganization {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    licenses: LicenseInformation[];
    _dataService: DataService;
    controller: DialogController;
    organizations: IOrganization[];
    selectedOrganization: IOrganization = null;

    constructor(controller: DialogController, dataService: DataService) {
        this.controller = controller;
        this._dataService = dataService;
    }

    async bind(){
        this.organizations = await this._dataService.getOrganizationsFromServerAsync();
    }

    activate(licenses: LicenseInformation[]) {
        this.licenses = licenses;
    }

    stopUpdate(){
        this.controller.cancel();
    }

    async confirmUpdate (){
        await Swal.fire({
            title: `Would you like to update the organization for all selected dongles to: ${this.selectedOrganization.name}?`,
            showDenyButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#4CBB17",
            confirmButtonText: `Yes, Update`,
            denyButtonText: `No, Close`,
        }).then(async (result) => {
            if (result.isConfirmed){
                var upgradeResponse = await this._dataService.updateKeylokOrganizationAsync(
                    {licenses: this.licenses, organizationId: this.selectedOrganization.organizationId});
                if (typeof upgradeResponse === "undefined"){
                    Swal.fire({
                        title: 
                            `Dongle organizations updated successfully`,
                        showDenyButton: false,
                        showCancelButton: false,
                        showConfirmButton: true,
                        denyButtonColor: `#4985BD`,
                        confirmButtonColor: "#4CBB17",
                        confirmButtonText: `Ok`,
                        allowOutsideClick: false,
                    }).then(async (result) => {
                        this.controller.ok();
                    });
                } else {
                    Swal.fire({
                        title: `Something went wrong when trying to update the organizations.`,
                        showDenyButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        denyButtonText: `Close`,
                    }).then(async (result) => {this.controller.ok();});
                }
            }
        });

    }
}

interface OrganizationUpdateDTO{
    licenses: Array<LicenseInformation>;
    organizationId: string;
}

interface LicenseInformation{
    licenseId: string;
    number:  number;
}

class IOrganization {
    organizationId: string;
	name?: string;
    softwareKeyID?: string;
}