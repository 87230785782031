import { pluck } from "rxjs/operators";
import {
    autoinject,
    bindable,
    bindingMode,
} from "aurelia-framework";
import { connectTo } from "aurelia-store";
import License from "components/license";
import { byteArrayDownloadUtility } from "resources/download-helper";
import { DialogController } from "aurelia-dialog";
import Swal from "sweetalert2";   
import { DataService } from "services/data-service";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class LicenseContacts {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    contactsInitializer: Object[];
    organizationName: string;
    organizationId: string;
    licenseContacts: LicenseContact[];
    licenseContactsInitial: LicenseContact[];
    controller: DialogController;
    _dataService: DataService;

    constructor(controller: DialogController, dataService: DataService) {
        this.controller = controller;
        this._dataService = dataService;
    }

    initializeContacts(contacts: any){
        this.licenseContacts = contacts;
        for (var contact of this.licenseContacts){
            contact.initialName = contact.name;
            contact.initialEmail = contact.email
            contact.initialPhone = contact.phone
        }
    }

    activate(contactsInitializer){
        this.initializeContacts(contactsInitializer[0]);
        this.licenseContacts.push(new LicenseContact());
        this.organizationName = contactsInitializer[1];
        this.organizationId = contactsInitializer[2];
    }

    confirmUpsert(contact: LicenseContact){
        Swal.fire({
            title: `Would you like to ${ contact.newContact ? " add new " : " update the "} contact information? This will modify the contact information for all licenses under this organization.`,
            showDenyButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#4CBB17",
            confirmButtonText: `Yes, ${ contact.newContact ? " Add " : " Update "}`,
        }).then(async (result) => {
            if(result.isConfirmed){
                await this.upsertContact(contact);
            }
        });
    }

    async upsertContact(contact: LicenseContact){
        if (contact.newContact) {
            contact.organizationId = this.organizationId
            await this._dataService.postLicenseContactToServerAsync(contact);
            this.initializeContacts( await this._dataService.getLicenseContactsFromServerAsync(this.organizationId));
            this.licenseContacts.push(new LicenseContact());
        } else {
            await this._dataService.updateLicenseContactAsync(contact);
            this.initializeContacts( await this._dataService.getLicenseContactsFromServerAsync(this.organizationId));
        }
    }

    confirmDelete(contact: LicenseContact){
        Swal.fire({
            title: `Would you like to delete ${contact.name}, license contact for ${this.organizationName}?`,
            showDenyButton: true,
            showCancelButton: true,
            showConfirmButton: false,
            denyButtonText: `Yes, Delete`,
        }).then(async (result) => {
            if (result.isDenied) {
                await this.deleteContact(contact);
            }
        });
    } 

    async deleteContact(contact: LicenseContact){
        await this._dataService.deleteLicenseContactAsync(contact.licenseContactId);
        this.initializeContacts( await this._dataService.getLicenseContactsFromServerAsync(this.organizationId));
        this.licenseContacts.push(new LicenseContact());
    }

}

class LicenseContact{
    licenseContactId = null;
    organizationId = null;
    name: string = "";
    email: string = "";
    phone: string = "";
    initialName: string = "";
    initialEmail: string = "";
    initialPhone: string = "";
    newContact: boolean = true;
}
