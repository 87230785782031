import { pluck } from "rxjs/operators";
import { IAsset, IOrganization, IProject, IUser } from "models/interfaces";
import { autoinject, observable } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import { connectTo } from "aurelia-store";

@autoinject()
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
        organization: (store) => store.state.pipe(pluck("organization")),
    },
})
export class PasswordResetDialog {
    controller: DialogController;
    _authenticationService: AuthenticationService;

    user: IUser;
    organization: IOrganization;
    darkUi: boolean;

    @observable password: string = "";
    @observable repeatPassword: string = "";

    showingErrors: boolean = false;
    passwordError: string = null;
    repeatPasswordError: string = null;

    constructor(
        controller: DialogController,
        authenticationService: AuthenticationService
    ) {
        this.controller = controller;
        this._authenticationService = authenticationService;
    }

    activate(user: IUser) {
        this.user = user;
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }
    organizationChanged(newOrg, oldOrg) {
        this.organization = newOrg;
    }

    passwordChanged(newValue, oldValue) {
        if (newValue === "" || newValue === null) {
            this.passwordError = "Password is required";
            return;
        }
        this.passwordError = null;
    }

    repeatPasswordChanged(newValue, oldValue) {
        if (newValue === "" || newValue === null) {
            this.repeatPasswordError = "Repeat password is required";
            return;
        }
        if (newValue != this.password) {
            this.repeatPasswordError = "Passwords must match";
            return;
        }
        this.repeatPasswordError = null;
    }

    async save() {
        if (this.password == null || this.password == "") {
            this.passwordError = "Password is required";
        }
        if (this.repeatPassword == null || this.repeatPassword == "") {
            this.repeatPasswordError = "Repeat password is required";
        }

        if (this.passwordError || this.repeatPasswordError) {
            this.showingErrors = true;
            return;
        }

        let payload = {
            userId: this.user.userId,
            password: this.password,
        };

        //Send new password to server here.
        let fetchUrl = `/resetpassword?organizationId=${this.organization.organizationId}`;

        const returnedData = await fetch(fetchUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
            body: JSON.stringify(payload),
        });

        this.controller.ok();
    }

    cancel() {
        this.controller.cancel();
    }
}
